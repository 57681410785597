@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html {
  height: 100%;
}

body {
  min-height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  min-width: 320px;
}

#root {
  min-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

video::-internal-media-controls-overlay-cast-button {
  display: none;
}

@keyframes herocoins {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.95);
  }
}

@keyframes herodragon {
  0%,
  100% {
    top: 20px;
  }
  50% {
    top: 22px;
  }
}

@keyframes herochars {
  0%,
  100% {
    top: 114px;
  }
  50% {
    top: 118px;
  }
}

@keyframes fadeDragon {
  0% {
    transform: translate3d(0, 50%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeChars {
  0% {
    transform: translate3d(0, 15%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeCoins {
  0% {
    transform: translate3d(0, 10%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeBottom {
  0% {
    opacity: 0.5;
    transform: translate3d(0, 5%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
